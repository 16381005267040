import React from 'react';
import Game from './Game';

import './App.css';

function getRoomFromURL() {
	const url = window.location.href;
	if (url.includes('/r/')) {
		return url.split('/r/')[1].split('/')[0];
	}
	return null;
}

class App extends React.PureComponent {
	constructor() {
		super();
		this.playerName = localStorage.getItem('questWho_nickname') || '';
		this.roomCode = getRoomFromURL();
	}

	render() {
		return (
			<div className='app'>
				<Game {...this} />
			</div>
		);
	}
}

export default App;
