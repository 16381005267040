import React, { useState, useEffect } from 'react';

import themes from './themes.json';
import logo from './logo.png';

import io from 'socket.io-client';

// const env = process.env.ENV;
const server_url = 'https://quest-who-server.onrender.com'; //env === 'live' ? 'https://quest-who-server.onrender.com' : 'http://localhost:3001';
const socket = io(server_url);

function uniqid() {
	const time = Date.now().toString(16);
	const randomPart = Math.random().toString(16).substr(2, 8);
	return 'id_' + time + randomPart;
}

function isValidWord(word) {
	return typeof word === 'string' && word.trim() !== '';
}

function setClientId() {
	let value = localStorage.getItem('questWho_client_id');
	if (!value) {
		value = uniqid();
		localStorage.setItem('questWho_client_id', value);
	}
	return value;
}

const Item = props => {
	const [visibility, setVisibility] = useState(true);

	const changeVisibility = () => {
		setVisibility(!visibility);
	};

	if (visibility) {
		return (
			<div className='item' onClick={changeVisibility}>
				<span>{props.item}</span>
				<span className='material-symbols-outlined'>visibility_off</span>
			</div>
		);
	}
	return (
		<div className='item' style={{ backgroundColor: '#959595' }} onClick={changeVisibility}>
			<span className='material-symbols-outlined'>visibility</span>
		</div>
	);
};

const Game = props => {
	const [theme, setTheme] = useState('');
	const [words, setWords] = useState([]);
	const [wordsInput, setWordsInput] = useState('');
	const [playing, setPlaying] = useState(false);
	const [roomCode, setRoomCode] = useState('');
	const [playerName, setPlayerName] = useState(props.playerName);
	const [currentPlayer, setCurrentPlayer] = useState('');
	const [question, setQuestion] = useState('');
	const [loading, setLoading] = useState(false);
	const [players, setPlayers] = useState([]);
	const clientId = setClientId();

	const createRoom = () => {
		if (!playerName) {
			alert('digite seu apelido.');
			return;
		}

		const words =
			themes[theme] ||
			wordsInput
				.split(',')
				.filter(item => isValidWord(item))
				.map(item => item.trim());
		setWords(words);

		if (words.length === 0) {
			alert('escolha um tema.');
			return;
		}

		socket.emit('createRoom', clientId, playerName, JSON.stringify(words));
	};

	const sendQuestion = () => {
		socket.emit('sendQuestion', roomCode, playerName, question);
	};

	const changePlayerName = e => {
		localStorage.setItem('questWho_nickname', e.target.value);
		setPlayerName(e.target.value);
	};

	const joinRoom = () => {
		socket.emit('joinRoom', clientId, props.roomCode, playerName);
	};

	/* arrumar isso aqui, ta escroto */
	useEffect(() => {
		if (props.roomCode && props.playerName) {
			socket.emit('joinRoom', clientId, props.roomCode, props.playerName);
		}
	}, [props.roomCode, clientId, props.playerName]);

	useEffect(() => {
		socket.on('createdRoom', (roomCode, updatedPlayers) => {
			const url = new URL(window.location);
			url.pathname = `/r/${roomCode}/`;
			window.history.pushState({}, '', url);

			setPlayers(updatedPlayers);
			setRoomCode(roomCode);
			setPlaying(true);
		});

		socket.on('roomNotExist', () => {
			window.location.href = '/';
		});

		socket.on('changeTurn', playerName => {});

		socket.on('joinedRoom', (roomCode, updatedPlayers, gameData) => {
			setWords(JSON.parse(gameData));
			setPlayers(updatedPlayers);
			setRoomCode(roomCode);
			setPlaying(true);
		});

		socket.on('updatePlayers', updatedPlayers => {
			setPlayers(updatedPlayers);
		});

		return () => {
			socket.off('updatePlayers');
		};
	}, []);

	if (loading) {
		return (
			<div>
				ACORDANDO O SERVIDOR
				<div>ele estava dormindo zZZzZZ</div>
			</div>
		);
	}
	if (props.roomCode && !playing) {
		return (
			<div>
				<div className='topbar'>
					<a href='/'>
						<img src={logo} alt='QUEST WHO?' />
					</a>
				</div>
				digite seu apelido para entrar na sala:
				<input value={playerName} onChange={changePlayerName} />
				<button onClick={joinRoom}>GO</button>
			</div>
		);
	}
	if (playing) {
		return (
			<div>
				<div className='topbar'>
					<a href='/'>
						<img src={logo} alt='QUEST WHO?' />
					</a>
				</div>
				<div className='link'>envie este link para seus amigos: https://www.questwho.com/r/{roomCode}/</div>
				<div className='info'>
					<div className='players'>
						<span style={{ color: '#F27457' }}>(3)</span> x <span style={{ color: '#04BF9D' }}>(2)</span>
					</div>
					<div className='text'>é a vez de {currentPlayer}</div>
					<div className='action'>...</div>
				</div>
				<div className='board'>
					{words.map((item, idx) => (
						<Item key={idx} item={item} />
					))}
				</div>
				<div className='questions'>
					<input value={question} onChange={e => setQuestion(e.target.value)} placeholder='escreva sua pergunta...' />
					<button onClick={sendQuestion}>enviar</button>
				</div>
			</div>
		);
	}

	return (
		<div>
			<div className='topbar'>
				<img src={logo} alt='QUEST WHO?' />
			</div>
			<div className='enter-game'>
				<div className='input-title'>
					jogue online em vários dispositivos em um tabuleiro compartilhado. para criar um novo jogo, insira seu apelido do jogo e
					clique em 'criar sala'. escolha um tema já existente ou crie o seu próprio separando as palavras por vírgulas (,).
				</div>
				<div className='input-field'>
					<input type='text' value={playerName} onChange={changePlayerName} placeholder='apelido' />
					<button onClick={createRoom}>criar sala</button>
				</div>
			</div>
			<div className='theme-game'>
				<div className='text'>selecione um tema:</div>
				<div className='themes'>
					{Object.keys(themes).map((tema, idx) => (
						<span
							key={idx}
							className='theme'
							onClick={() => setTheme(tema)}
							style={{ backgroundColor: theme === tema ? '#03a69665' : '' }}
						>
							{tema.toLowerCase()}
						</span>
					))}
				</div>
				<div className='custom-theme'>
					<span
						className='theme'
						onClick={() => setTheme('custom')}
						style={{ backgroundColor: theme === 'custom' ? '#03a69665' : '' }}
					>
						{theme === 'custom' ? '▾' : '▸'} personalizado ({wordsInput.split(',').filter(i => isValidWord(i)).length} palavras)
					</span>
					{theme === 'custom' ? <textarea value={wordsInput} onChange={e => setWordsInput(e.target.value)} /> : null}
				</div>
			</div>
		</div>
	);
};

export default Game;

// EDU STARTARI, GI PIPOLO, TERESA, JU LOBATO, JULIA, FELIPE, PAULINHO, PHIL, JAY P, FAFIS, VIT, ROSSI, LUKE, LUKIN, KAUE, HEITOR, RENAN, CEBOLA, ELIZABETE, GIU PIPOLO, MIGUEL, DEUCHER, SUZUKI, CARLA, JULIO, RICAO, THALITA, THAIS, RAMON, LUANA
